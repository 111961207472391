<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="224" height="52" viewBox="0 0 224 52" fill="none">
    <g id="surface1">
      <path id="Vector" d="M63.7161 19.1849C64.7622 18.7787 65.8844 18.5857 67.0066 18.6264C69.4186 18.5603 71.6173 19.9923 72.5313 22.2266L72.6989 22.6125H76.3905L76.0909 21.7594C75.4562 19.845 74.2324 18.1795 72.5973 16.9964C70.8099 15.7472 68.6772 15.0972 66.4988 15.1429C63.4267 15.1429 60.8979 16.2093 58.9836 18.3268C57.1809 20.3325 56.272 22.8968 56.272 25.9436C56.272 28.9903 57.1809 31.5597 58.9836 33.5705C60.8979 35.6829 63.4267 36.7493 66.4988 36.7493C68.6721 36.8001 70.8099 36.1552 72.5973 34.9111C74.2324 33.733 75.4613 32.0675 76.0909 30.148L76.3956 29.3H72.6837L72.5161 29.686C71.6021 31.9202 69.4034 33.3522 66.9914 33.2862C64.5845 33.2862 62.8123 32.6007 61.5682 31.1941C60.3851 29.8586 59.7859 28.0966 59.7859 25.9486C59.7859 23.8007 60.3851 22.0387 61.5682 20.7083C62.1623 20.0482 62.8986 19.5302 63.7161 19.1849Z" fill="#000D45"/>
      <path id="Vector_2" d="M86.049 20.013C83.637 20.013 81.6617 20.8407 80.1282 22.4707C78.7013 24.0245 77.9803 26.015 77.9803 28.3915C77.9194 30.5851 78.7217 32.7127 80.2196 34.3173C81.7582 35.9423 83.7691 36.7649 86.2014 36.7649C88.1106 36.7649 89.7051 36.2977 90.939 35.3786C92.1628 34.4697 93.0971 33.2256 93.6303 31.7987L93.9604 30.9304H90.1875L90.0047 31.2706C89.243 32.7381 88.004 33.449 86.2318 33.449C85.0385 33.4998 83.8808 33.0631 83.0175 32.2456C82.3574 31.621 81.8902 30.8136 81.6871 29.9301H94.1279V28.3915C94.1279 26.0201 93.3967 24.0245 91.98 22.4656C90.4465 20.8305 88.2884 19.9368 86.049 20.013ZM83.0074 24.4561C84.7592 22.9632 87.3337 22.9632 89.0907 24.4561C89.7 25.0198 90.1367 25.7459 90.3551 26.5533H81.743C81.9613 25.751 82.398 25.0198 83.0074 24.4561Z" fill="#000D45"/>
      <path id="Vector_3" d="M107.139 20.4801C106.24 20.1501 105.285 19.9927 104.325 20.013C103.259 20.0028 102.213 20.2973 101.314 20.8711C100.969 21.0946 100.634 21.3383 100.324 21.6074V20.2516H96.7949V36.5262H100.324V27.2489C100.278 26.1877 100.685 25.1569 101.441 24.4155C102.177 23.6894 103.178 23.2933 104.209 23.3136C105.173 23.273 106.108 23.6487 106.773 24.3444C107.443 25.0502 107.763 25.9795 107.763 27.2032V36.5262H111.292V27.1626C111.292 24.9944 110.602 23.2171 109.251 21.8867C108.657 21.2672 107.936 20.7899 107.139 20.4801Z" fill="#000D45"/>
      <path id="Vector_4" d="M119.744 14.885H116.215V20.2574H113.158V23.5123H116.215V36.5269H119.744V23.5123H123.187V20.2574H119.744V14.885Z" fill="#000D45"/>
      <path id="Vector_5" d="M132.406 20.0133C129.994 20.0133 128.013 20.841 126.48 22.471C125.058 24.0248 124.332 26.0153 124.332 28.3918C124.271 30.5854 125.078 32.7131 126.576 34.3177C128.115 35.9426 130.126 36.7652 132.553 36.7652C134.462 36.7652 136.057 36.298 137.296 35.3789C138.519 34.47 139.449 33.2259 139.982 31.799L140.312 30.9307H136.539L136.361 31.2709C135.595 32.7384 134.361 33.4494 132.583 33.4494C131.395 33.5001 130.237 33.0634 129.369 32.2459C128.709 31.6213 128.247 30.8139 128.039 29.9304H140.479V28.3918C140.479 26.0204 139.748 24.0248 138.332 22.4659C136.808 20.841 134.807 20.0133 132.406 20.0133ZM129.359 24.4564C131.116 22.9635 133.69 22.9635 135.442 24.4564C136.052 25.0201 136.493 25.7462 136.707 26.5536H128.1C128.313 25.7513 128.75 25.0201 129.359 24.4564Z" fill="#000D45"/>
      <path id="Vector_6" d="M148.88 20.5561C148.047 20.9318 147.3 21.4752 146.686 22.1505V20.2565H143.152V36.526H146.676V29.2951C146.64 27.8428 147.097 26.421 147.976 25.2684C148.783 24.1665 150.073 23.5317 151.434 23.5622H152.338V20.0128H151.551C150.632 19.9924 149.718 20.1752 148.88 20.5561Z" fill="#000D45"/>
      <path id="Vector_7" d="M162.934 20.0132C161.218 19.9523 159.542 20.5312 158.227 21.6382V14.2549H154.703V36.5265H158.227V35.1758C159.496 36.2574 161.121 36.821 162.787 36.7651C165.234 36.7651 167.215 35.9374 168.667 34.3075C170.119 32.6825 170.896 30.5701 170.84 28.3917C170.901 26.2133 170.124 24.0959 168.672 22.476C167.22 20.8409 165.117 19.9422 162.934 20.0132ZM167.301 28.3917C167.357 29.7018 166.91 30.9865 166.052 31.9818C165.194 32.9618 163.939 33.5001 162.634 33.4493C161.35 33.5102 160.111 32.9669 159.288 31.9818C158.46 30.9713 158.034 29.6866 158.09 28.3816C158.034 27.0715 158.46 25.7868 159.293 24.7712C160.116 23.7861 161.355 23.2428 162.634 23.3088C163.939 23.258 165.194 23.7963 166.052 24.7712C166.915 25.7766 167.362 27.0715 167.301 28.3917Z" fill="#000D45"/>
      <path id="Vector_8" d="M185.368 21.6269C184.099 20.5352 182.469 19.9563 180.793 20.0122C178.361 20.0122 176.375 20.8399 174.893 22.4749C173.461 24.1049 172.694 26.2173 172.755 28.3907C172.699 30.564 173.461 32.6764 174.898 34.3064C176.36 35.9313 178.462 36.8301 180.646 36.7641C182.555 36.7641 184.145 36.2258 185.368 35.1696V36.5254H188.897V20.2559H185.368V21.6269ZM185.49 28.3907C185.546 29.6957 185.119 30.9804 184.292 31.9909C183.469 32.9811 182.23 33.5244 180.945 33.4584C179.646 33.5092 178.391 32.9709 177.528 31.9909C176.675 30.9956 176.228 29.716 176.284 28.4008C176.228 27.0907 176.67 25.806 177.528 24.8108C178.391 23.8307 179.646 23.2925 180.945 23.3433C182.23 23.2823 183.469 23.8257 184.297 24.8108C185.114 25.8162 185.541 27.0907 185.49 28.3907Z" fill="#000D45"/>
      <path id="Vector_9" d="M202.587 27.5441C201.77 27.209 200.917 26.9551 200.048 26.7875C199.2 26.625 198.403 26.4219 197.682 26.2442C197.093 26.102 196.529 25.8684 196.011 25.5587C195.722 25.386 195.554 25.0712 195.575 24.731C195.575 24.3146 195.575 23.2127 198.398 23.2127C200.993 23.2127 201.424 24.2283 201.526 24.995L201.592 25.5485H205.151L205.065 24.8376C204.948 23.5326 204.314 22.3241 203.308 21.4812C202.14 20.5062 200.541 20.0187 198.55 20.0187C196.484 20.0187 194.874 20.4402 193.777 21.273C192.629 22.1362 191.979 23.5022 192.04 24.9341C191.995 25.8837 192.32 26.818 192.944 27.5289C193.528 28.1433 194.239 28.6206 195.026 28.9304C195.844 29.2554 196.687 29.4991 197.555 29.6616C198.408 29.8292 199.2 30.0272 199.916 30.2202C200.505 30.3776 201.064 30.6213 201.577 30.9514C201.881 31.1444 202.054 31.4846 202.029 31.8451C202.029 32.2818 201.912 32.8353 201.003 33.1958C200.632 33.338 200.246 33.4345 199.855 33.4903C199.413 33.5462 198.962 33.5767 198.515 33.5716C198.063 33.5716 197.611 33.5005 197.179 33.3685C195.996 32.9673 195.387 32.2716 195.275 31.119L195.214 30.5452H191.624L191.66 31.2205C191.715 32.749 192.431 34.1708 193.625 35.1203C194.914 36.2222 196.656 36.7757 198.799 36.7757C200.805 36.7757 202.43 36.3441 203.633 35.5012C204.898 34.6328 205.624 33.1704 205.553 31.6369C205.598 30.6721 205.278 29.7225 204.654 28.9863C204.08 28.3617 203.374 27.8691 202.587 27.5441Z" fill="#000D45"/>
      <path id="Vector_10" d="M223.559 28.3918C223.559 26.0204 222.828 24.0248 221.411 22.4659C218.141 19.1958 212.84 19.1958 209.57 22.4659C208.143 24.0248 207.422 26.0103 207.422 28.3867C207.361 30.5804 208.168 32.708 209.661 34.3126C211.2 35.9375 213.21 36.7601 215.643 36.7601C217.552 36.7601 219.146 36.293 220.401 35.3739C221.624 34.4649 222.559 33.2208 223.087 31.794L223.417 30.9256H219.629L219.451 31.2709C218.684 32.7334 217.445 33.4443 215.673 33.4443C214.48 33.4951 213.322 33.0584 212.459 32.2408C211.799 31.6162 211.337 30.8089 211.129 29.9253H223.574L223.559 28.3918ZM212.449 24.4564C214.201 22.9635 216.78 22.9635 218.532 24.4564C219.141 25.0252 219.578 25.7513 219.796 26.5536H211.189C211.403 25.7513 211.839 25.0252 212.449 24.4564Z" fill="#000D45"/>
      <path id="Vector_11" d="M6.16064 42.7418L8.13086 43.8792L21.4044 51.5468C22.4505 52.1511 23.7402 52.1511 24.7914 51.5468L44.4834 40.1673C45.4532 39.6087 46.0524 38.5728 46.0524 37.4557V30.6564L32.2051 38.6338L24.4765 43.0871C24.3597 43.1582 24.2379 43.214 24.1109 43.2648C18.4186 46.1236 11.6752 45.9256 6.16064 42.7418Z" fill="url(#paint0_linear_426_889)"/>
      <path id="Vector_12" d="M0.142578 21.3436V37.3033C0.142578 38.5118 0.787468 39.629 1.83351 40.2332L6.15986 42.7366C11.6694 45.9103 18.4077 46.1083 24.0898 43.2597C23.3078 43.5643 22.4294 43.4983 21.7032 43.0768L13.9899 38.6236L9.82609 36.2166L8.1707 35.262C3.19946 32.3879 0.142578 27.0866 0.142578 21.3436Z" fill="url(#paint1_linear_426_889)"/>
      <path id="Vector_13" d="M30.8326 21.5314L23.094 17.0629L15.3604 21.5314L23.094 25.9999L30.8326 21.5314Z" fill="#002AF2"/>
      <path id="Vector_14" d="M15.3604 21.5311V30.4682L23.094 34.9367V25.9997L15.3604 21.5311Z" fill="#009EFF"/>
      <path id="Vector_15" d="M45.966 13.2391C45.9203 13.1122 45.8695 12.9903 45.7984 12.8735L38.5624 17.0577L30.8339 21.5313L23.0952 25.9998V34.9369L30.7527 30.5141H30.8339L34.099 28.6251L45.4429 22.034L45.575 21.9477L45.6765 21.8563C45.905 21.6278 46.0371 21.318 46.0421 20.988V13.7926C46.0472 13.7114 46.0472 13.6301 46.0421 13.5489C46.0269 13.4423 46.0015 13.3407 45.966 13.2391Z" fill="url(#paint2_linear_426_889)"/>
      <path id="Vector_16" d="M16.4781 3.29681L1.83859 11.7565C0.792546 12.3608 0.147656 13.4779 0.142578 14.6865V21.3436C0.142578 27.0866 3.20454 32.3879 8.1707 35.262L9.00347 35.7393C8.14531 35.2468 7.62229 34.3328 7.62229 33.3476V18.6523C7.62229 12.3202 10.9991 6.4654 16.4781 3.29681Z" fill="url(#paint3_linear_426_889)"/>
      <path id="Vector_17" d="M39.33 8.85707L38.0554 8.12078L38.9339 8.63365L24.792 0.4532C23.7409 -0.151067 22.4511 -0.151067 21.4051 0.4532L16.4795 3.29681C10.9954 6.4654 7.62373 12.3151 7.61865 18.6523C7.61865 17.6621 8.14675 16.7532 8.99983 16.2606L16.0073 12.2339L17.1041 11.589L21.7301 8.91293L21.8367 8.85707C27.2497 5.72911 33.917 5.72911 39.33 8.85707Z" fill="url(#paint4_linear_426_889)"/>
      <path id="Vector_18" d="M45.7285 12.7624C45.693 12.7065 45.6523 12.6557 45.6066 12.5999C45.4797 12.4475 45.3274 12.3155 45.1547 12.2139L39.3405 8.85747C33.9224 5.72443 27.2501 5.72443 21.832 8.85747L21.7305 8.91333C22.5836 8.42078 23.6347 8.42078 24.4878 8.91333L38.5636 17.0582L45.7894 12.8842C45.7742 12.8436 45.7539 12.803 45.7285 12.7624Z" fill="url(#paint5_linear_426_889)"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_426_889" x1="17.2928" y1="46.1655" x2="42.7697" y2="32.466" gradientUnits="userSpaceOnUse">
        <stop stop-color="#009EFF"/>
        <stop offset="0.11" stop-color="#0096FF"/>
        <stop offset="1" stop-color="#005CFF"/>
      </linearGradient>
      <linearGradient id="paint1_linear_426_889" x1="-0.382874" y1="27.3036" x2="14.9938" y2="44.0572" gradientUnits="userSpaceOnUse">
        <stop stop-color="#009EFF"/>
        <stop offset="0.2" stop-color="#009AFF"/>
        <stop offset="0.38" stop-color="#008FFF"/>
        <stop offset="0.57" stop-color="#007BFF"/>
        <stop offset="0.75" stop-color="#0060FF"/>
        <stop offset="0.93" stop-color="#003DFF"/>
        <stop offset="1" stop-color="#002EFF"/>
      </linearGradient>
      <linearGradient id="paint2_linear_426_889" x1="46.0519" y1="23.9089" x2="24.326" y2="23.9089" gradientUnits="userSpaceOnUse">
        <stop stop-color="#009EFF"/>
        <stop offset="0.37" stop-color="#0070FF"/>
        <stop offset="0.79" stop-color="#0040FF"/>
        <stop offset="1" stop-color="#002EFF"/>
      </linearGradient>
      <linearGradient id="paint3_linear_426_889" x1="14.0425" y1="10.4117" x2="2.16429" y2="28.3107" gradientUnits="userSpaceOnUse">
        <stop stop-color="#009EFF"/>
        <stop offset="0.21" stop-color="#009AFF"/>
        <stop offset="0.39" stop-color="#0090FF"/>
        <stop offset="0.57" stop-color="#007DFF"/>
        <stop offset="0.74" stop-color="#0064FF"/>
        <stop offset="0.91" stop-color="#0043FF"/>
        <stop offset="1" stop-color="#002EFF"/>
      </linearGradient>
      <linearGradient id="paint4_linear_426_889" x1="39.173" y1="9.32784" x2="10.4943" y2="9.32784" gradientUnits="userSpaceOnUse">
        <stop stop-color="#009EFF"/>
        <stop offset="0.32" stop-color="#009CFF"/>
        <stop offset="0.5" stop-color="#0094FF"/>
        <stop offset="0.64" stop-color="#0086FF"/>
        <stop offset="0.76" stop-color="#0073FF"/>
        <stop offset="0.87" stop-color="#0059FF"/>
        <stop offset="0.96" stop-color="#003BFF"/>
        <stop offset="1" stop-color="#002EFF"/>
      </linearGradient>
      <linearGradient id="paint5_linear_426_889" x1="31.3789" y1="6.54162" x2="37.7307" y2="18.1533" gradientUnits="userSpaceOnUse">
        <stop stop-color="#002EFF"/>
        <stop offset="0.24" stop-color="#0060FF"/>
        <stop offset="0.42" stop-color="#0081FF"/>
        <stop offset="0.53" stop-color="#008DFF"/>
        <stop offset="0.61" stop-color="#0088FF"/>
        <stop offset="0.72" stop-color="#007AFF"/>
        <stop offset="0.83" stop-color="#0062FF"/>
        <stop offset="0.95" stop-color="#0040FF"/>
        <stop offset="1" stop-color="#002EFF"/>
      </linearGradient>
    </defs>
  </svg>
</template>;
